import { Button } from "package:/components/elements/Button";
import type { Track } from "@sv/elements/track";
import { twMerge } from "tailwind-merge";
import { computed, defineComponent, ref } from "vue";
import { useListener } from "#imports";

export const Tabs = defineComponent(
  (props: {
    labels: (string | undefined)[];
    active: number;
    onChange: (tab: number) => void;
  }) => {
    const track = ref<Track>();
    const position = ref(0);
    const overflowWidth = ref(0);

    const updateState = () => {
      overflowWidth.value = track.value?.overflowWidth || 0;
      position.value = track.value?.position.x || 0;
    };

    const showShadow = computed(() => {
      return overflowWidth.value > 0 && overflowWidth.value - position.value > 0;
    });

    useListener(window, "resize", updateState);

    return () => (
      <div class="relative w-full select-none">
        <a-track
          ref={track}
          onChange={updateState}
          onScroll={updateState}
          overflowscroll
          class="before:absolute before:bottom-[0.5px] before:block before:h-[1px] before:w-full before:rounded before:bg-green-200"
        >
          <ul class="flex">
            {props.labels?.map((str, i) => {
              return (
                <li class="pr-5" key={`tab_${i}`}>
                  <div
                    class={twMerge(
                      "relative",
                      i === props.active
                        ? "before:absolute before:bottom-0 before:block before:h-[2px] before:w-full before:rounded before:bg-white before:content-['']"
                        : "",
                      i === props.active ? "text-white" : "text-green-200",
                    )}
                  >
                    <Button
                      variant="ghost"
                      class="whitespace-nowrap"
                      onClick={() => {
                        props.onChange(i);
                      }}
                    >
                      {str}
                    </Button>
                  </div>
                </li>
              );
            })}
          </ul>
        </a-track>

        <div
          class={twMerge(
            "pointer-events-none absolute top-0 right-[-1px] h-full w-[80px]",
            "bg-gradient-green-800 opacity-0 transition-opacity duration-300",
            showShadow.value && "opacity-100",
          )}
        />
      </div>
    );
  },
  {
    props: ["labels", "active", "onChange"],
  },
);
